@import "../styles/styles2.scss";

.landing-cards {
  --grid-min-item-size: 300px;

  > ._card {
    display: grid;
    grid-template:
      "icon title" auto
      "icon content" 1fr
      / 3rem auto;

    width: fit-content;
    ._icon {
      grid-area: icon;
    }
    ._title {
      grid-area: title;
      width: fit-content;
    }
    ._content {
      grid-area: content;
    }
  }
}

.mtfix {
  margin-top: 0 !important;
}

.nosel {
  img {
    user-select: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}

.flex {
  display: flex;
}

.section-hero-grid {
  --gap: 58px;
  --inner-image-offset-margin: 30px;
  --outer-image-offset-margin: 8px;
  --icon-width: 141px;
  --icon-height: 141px;
  --hw-width: 260px;
  --hw-height: 526px;
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns:
    var(--hw-width) calc(var(--hw-height) - var(--gap) - var(--icon-width)) var(--icon-width) calc(
      var(--hw-height) - var(--gap) - var(--icon-width)
    )
    var(--hw-width);
  grid-template-rows: var(--hw-width) var(--icon-height) var(--hw-width);
}

.svg-fix {
  svg {
    fill: none !important;
  }
}
.overflow-x-none {
  overflow-x: hidden !important;
  position: relative;
}

.width-t {
  position: relative;
  display: flex;
  width: min(130ch, 100%);
  margin-left: 1rem;
  margin-right: 1rem;
}

.mt0 {
  margin-top: 0 !important;
}

.width-t2 {
  position: relative;
  display: flex;
  width: min(140ch, 100%);
  margin-left: 1rem;
  margin-right: 1rem;
  //margin-bottom: 6rem;
}
.landing-hero {
  position: absolute;
  object-fit: fill;
  right: 0;
  z-index: -1;
  user-select: none;
  transform: translateY(30px) translateX(30px);
}

.small-auto {
  margin-left: 2rem;
}
@media screen and (max-width: 900px) {
  .small-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1100px) {
  .landing-hero {
    transform: scale(125%) translateY(-20px);
  }
}

@media screen and (min-width: 1300px) {
  .landing-hero {
    transform: scale(135%) translateY(-40px);
  }
}
@media screen and (min-width: 1600px) {
  .landing-hero {
    transform: scale(140%) translateY(-40px);
  }
}

.landing-discovery-image {
  object-position: top left;
  object-fit: contain;
}

.number-card-container {
  display: grid;
  place-items: center;
  width: 100%;
  gap: 5rem;
}
.number-card {
  display: grid;
  grid-template: "icon title" auto "value value" auto / min-content 1fr;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  width: 250px;
  pointer-events: none;
  user-select: none;

  > ._icon {
    grid-area: icon;
  }
  > ._main_title {
    grid-area: title;
  }
  > ._value {
    grid-area: value;
  }
}

.number-card[data-title="revenue"] {
  rotate: -10deg;
  translate: -20% 0;
}
.number-card[data-title="sales"] {
  rotate: -10deg;
  translate: -20% -30%;
}

.number-card[data-title="expenses"] {
  rotate: 10deg;
}
.number-card-container > img {
  position: absolute;
  translate: 0 10%;
  pointer-events: none;
  user-select: none;
}

.landing-footer {
  margin-top: 2rem;
}
@include xl {
  .landing-footer {
    margin-top: 6rem;
  }
}
// .landing-footer::before {
//   display: block;
//   content: "";
//   width: 100%;
//   height: 8px;
//   --blue-gradient-angle: 270deg !important;
//   background: linear-gradient(
//     var(--blue-gradient-angle),
//     var(--blue-gradient-start) 0%,
//     var(--blue-gradient-stop) 100%
//   );
// }

.cta-heartbeat {
  position: relative;
}
.cta-heartbeat::after {
  content: "";
  display: block;
  position: absolute;
  animation: heartbeat 4s ease-out infinite;

  border: 2px solid var(--light-blue-64);
  z-index: -1;
  filter: blur(2px);
  inset: -10px;
  border-radius: 6px;
}

.sparkle_icon {
  position: absolute;
  left: 31.5rem;
  top: -1.5rem;
  z-index: -1;
}

._title {
  position: relative;
}

:root {
  --margin-cross: 0.5rem;
  --flow-gap: 0.5rem;
  --min-grey: rgba(102, 115, 133, 1);
}

._main_title {
  position: relative;
}

._subtitle {
  margin-top: 0.5rem;
}

.email_capture {
  margin-top: 3rem;
  display: inline-flex;
  align-items: center;
  gap: -0.5rem;
}

.email_input {
  border: 1.167px solid var(--Dark-16, rgba(128, 140, 157, 0.5));
  box-shadow: 0px 2.33333px 2.33333px 0px rgba(0, 0, 0, 0.04);
  width: 23rem;
  height: 3.25rem;
  z-index: 1;
}

.roundedfull {
  border-radius: 60px !important;
}

.email_button {
  width: 8.75rem;
  height: 3.25rem;
}

.title_section {
  margin-top: 3rem;
}

@keyframes heartbeat {
  0% {
    opacity: 0;
    scale: 0.8;
  }
  10% {
    opacity: 1;
    scale: 0.95;
  }
  33% {
    opacity: 0;
    scale: 1;
  }
  100% {
    opacity: 0;
    scale: 1;
  }
}
.w-65em {
  width: min(100%, 2650px);
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
.flow-large-landing {
  margin-top: 0 !important;
}

@media all and (max-width: 899px) {
  .flow-large-landing {
    display: flex;
    flex-direction: column;
  }
  .landing-hero {
    position: relative;
    object-fit: fill;
    width: 100%;
    height: auto;
    z-index: -1;
    user-select: none;
    transform: none;
    opacity: 1;
    transform: scale(150%);
  }
}
@media all and (max-width: 600px) {
  .landing-hero {
    transform: scale(150%);
  }
}

@media all and (min-width: 900px) {
  .flow-large-landing {
  }
}
@media all and (max-width: 900px) {
  .flow-large {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landing-discovery-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
  }

  .discovery_subtitle {
    text-align: center;
  }

  .landing-discovery-image {
    object-position: center;
  }

  ._main_title {
    position: static;
  }

  .sparkle_container {
    position: relative;
  }

  ._title {
    position: static;
  }

  .sparkle_icon {
    top: -6.6rem;
  }

  .email_capture {
    margin-bottom: 8rem;
  }
}

.title_container {
  padding-left: 1rem;
  display: none;
}

.sparkle_icon2 {
  position: absolute;
  display: none;
  z-index: -1;
}

@media all and (max-width: 640px) {
  .sparkle_icon {
    display: none;
  }
}

@media all and (max-width: 598px) {
  .sale_title {
    font-size: 44px;
  }

  .title_section2 {
    height: 25rem;
  }

  .title_container {
    display: block;
  }

  .email_button {
    margin-bottom: 4rem;
  }

  .sparkle_icon2 {
    display: block;
    left: 14.75rem;
    top: -4rem;
  }
}

@media all and (max-width: 430px) {
  .discover {
    font-size: 32px;
  }

  .start-selling-today {
    font-size: 32px;
  }
}

@media all and (max-width: 335px) {
  .sparkle_icon2 {
    display: none;
  }
}

.review__background {
  position: absolute;
  top: -150%;
  left: 0%;
  z-index: -99;
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.imgcontainter {
  position: relative;
  width: full;
}

.reviewsheader {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media all and (max-width: 640px) {
  .reviewsheader {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.list-style-none {
  list-style: none;
}

.header {
  font-family: var(--font-sfpro);
  letter-spacing: -0.2rem;
  font-weight: 600;
  font-size: 66px;
}

.bgcolored {
  background-image: linear-gradient(109.67deg, #306aff, #55b2ff 21.04%, #1b5afd 49.48%, #448cff 70.04%, #3e86ff);
}
.fg-blue-gradient-dark {
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(109.67deg, #306aff, #55b2ff 21.04%, #1b5afd 49.48%, #448cff 70.04%, #3e86ff);
}

.fg-blue-gradient-light {
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(109.67deg, #13a8ff, #13a8ff 10%, #48bdff 40%);
}
.fixidk {
  justify-content: center;
}
@media (min-width: 1024px) {
  .fixidk {
    justify-content: flex-end;
  }
}
